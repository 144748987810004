<template>
  <v-dialog v-model="showDialog" width="600">
    <v-card class="my-0 mx-0">
      <v-card-title class="pr-3">
        {{ $ml.get(editingRule ? "edit_rule" : "add_new_rule") }}
        <v-spacer />
        <v-btn large icon @click="$emit('close')">
          <v-icon v-text="'mdi-close'" />
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="d-flex flex-column my-2 mr-1">
          <v-col class="mb-0 pb-0">
            <v-select
              v-if="!editingMoreThanOne"
              :items="optionsWhenRuleOccur"
              v-model="type"
              :disabled="!!editingRule"
              outlined
              dense
            >
              <template v-slot:prepend>
                <v-icon
                  class="mr-3"
                  v-text="'mdi-calendar'"
                  :dark="$vuetify.theme.dark"
                />
              </template>
            </v-select>

            <v-row v-if="canChooseDays" no-gutters class="ma-0 pa-0 mb-6">
              <v-col
                lg="2"
                sm="12"
                :class="`${
                  $vuetify.breakpoint.mdAndUp ? 'd-flex align-center' : ''
                } caption`"
              >
                {{
                  $ml.get(
                    editingRule
                      ? editingMoreThanOne
                        ? "days_upper"
                        : "choose_day"
                      : "choose_days"
                  )
                }}:
              </v-col>
              <v-col lg="8" sm="12">
                <v-btn-toggle
                  v-model="days"
                  :multiple="editingMoreThanOne || !editingRule"
                  class="mx-auto"
                >
                  <v-btn
                    text
                    small
                    v-for="(item, i) in getWeekDays"
                    :key="i"
                    :value="i + 1"
                    :disabled="editingMoreThanOne"
                  >
                    {{ item.day_name }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="d-flex align-center">
            <v-icon
              class="mr-5"
              v-text="'mdi-clock-outline'"
              :dark="$vuetify.theme.dark"
            />
            <v-text-field
              :error="ruleDisableStarHourLaterThanEndHour"
              :error-messages="customErrorMessage"
              v-model="start_hour"
              :label="$ml.get('start_hour')"
              outlined
              dense
              :disabled="all_day"
              style="max-height: 35px"
              class="mr-4"
              type="time"
            />

            <v-text-field
              :error="ruleDisableStarHourLaterThanEndHour"
              v-model="end_hour"
              :label="$ml.get('end_hour')"
              outlined
              dense
              :disabled="all_day"
              style="max-height: 35px"
              type="time"
            />
          </v-col>
          <v-col>
            <Alert
              v-if="ruleDisableStarHourLaterThanEndHour"
              :showAction="false"
              icon="mdi-alert-octagon"
              dense
              color="error"
              type="error"
              class="mb-0 mt-4"
            >
              <span
                >{{ $ml.get("starthour_later_than_endhour") }}.
                <a
                  href="https://conectasuite.com/perguntas-frequentes/horario-inicial-maior-que-horario-final-em-turnos/"
                  target="_blank"
                  >{{ $ml.get("need_help_question") }}</a
                >
              </span>
            </Alert>
          </v-col>
        </v-row>
        <!-- DEFINIR COMO O DIA TODO -->
        <v-checkbox
          v-model="all_day"
          dense
          :false-value="false"
          :true-value="true"
          :indeterminate="false"
          class="mt-6 mb-0 py-0"
          :label="`${$ml.get('define_as')} ${$ml.get('all_day').toLowerCase()}`"
          style="max-height: 35px; width: fit-content"
        ></v-checkbox>
      </v-card-text>

      <v-card-actions class="mx-3 pb-5 pt-0 mt-0">
        <v-spacer></v-spacer>

        <v-btn
          color="grey"
          text
          class="text-body-1 font-weight-medium text-none"
          @click="$emit('close')"
        >
          {{ $ml.get("cancel") }}
        </v-btn>
        <v-btn
          color="accent"
          class="text-body-1 font-weight-medium text-none elevation-0 px-4"
          @click="save()"
          :disabled="ruleDisableStarHourLaterThanEndHour"
        >
          {{ `${$ml.get("apply")}` }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  getWeekDays,
  getCurrentWeekDay,
  formatDate,
  getCurrentWeekNumber,
} from "@/helpers/utils";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddOrEditRulesDialog",
  props: {
    show: { type: Boolean, required: true },
    date: { type: String, default: () => new Date().toString() },
    editingRule: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      type: null,
      days: [],
      start_hour: null,
      end_hour: null,
      all_day: null,
    };
  },
  watch: {
    editingRule: {
      immediate: true,
      handler(value) {
        this.setEditingRule(value);
      },
    },
    all_day() {
      if (this.all_day) {
        this.start_hour = "00:00";
        this.end_hour = "23:59";
      }
    },
    type() {
      this.all_day = false;
      if (["weekly"].includes(this.type)) {
        this.days = [];
        this.days.push(this.currentWeekDayNumber);
      }
    },
  },
  computed: {
    ...mapGetters(["selectedGroup"]),
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        if (this.showDialog) {
          this.$emit("close");
          this.setEditingRule();
        }
      },
    },
    getWeekDays,

    /* USADO QUANDO PRECISAMOS RESETAR A REGRA */
    default() {
      return {
        type: "every_day",
        days: [],
        start_hour: "00:00",
        end_hour: "23:59",
        all_day: false,
      };
    },

    editingMoreThanOne() {
      return (
        this.editingRule &&
        this.editingRule.rules_ids &&
        this.editingRule.rules_ids.length > 1
      );
    },

    dateJs() {
      return new Date(this.date);
    },

    currentWeekDay() {
      return getCurrentWeekDay(this.dateJs);
    },

    currentWeekDayNumber() {
      return parseInt(getCurrentWeekNumber(this.dateJs)) + 1;
    },

    canChooseDays() {
      return this.type === "choose_days";
    },
    optionsWhenRuleOccur() {
      return [
        {
          text: this.$ml.get("everyday"),
          value: "every_day",
        },
        {
          text: this.$ml.get(this.editingRule ? "choose_day" : "choose_days"),
          value: "choose_days",
        },
        {
          text: `${this.$ml.get("weekly")}: ${this.$ml.get("each")} ${
            this.currentWeekDay
          }`,
          value: "weekly",
        },
      ];
    },

    coupleOfDays() {
      return ["choose_days", "weekly"].includes(this.type);
    },
    allDays() {
      return ["every_day"].includes(this.type);
    },

    ruleDisableStarHourLaterThanEndHour() {
      return this.start_hour > this.end_hour;
    },

    customErrorMessage() {
      return this.ruleDisableStarHourLaterThanEndHour
        ? this.$ml.get("starthour_later_than_endhour")
        : "";
    },
  },
  methods: {
    ...mapActions(["setGroupRule", "updateGroupRule"]),

    getCurrentWeekNumber,

    async save() {
      if (this.editingRule) {
        const payload = this.getUpdatePayload();
        await this.updateGroupRule(payload);
      } else {
        const payload = this.getPayload();
        await this.setGroupRule(payload);
      }
      this.$emit("close");
      this.setEditingRule();
    },

    getUpdatePayload() {
      const rules_ids = this.editingRule.rules_ids;
      let info = {
        key: this.selectedGroup.key,
        payload: {
          rules_ids,
          rule: {
            day: rules_ids.length == 1 ? this.days : 0,
            start_hour: this.start_hour,
            end_hour: this.end_hour,
          },
        },
      };
      return info;
    },

    getPayload() {
      let info = {
        key: this.selectedGroup.key,
        payload: {
          rules: [],
        },
        list_special_dates: this.selectedGroup.list_special_dates,
      };

      /* SE FOR TODOS OS DIAS */
      if (this.allDays) {
        const days_of_the_week = 7;
        for (let day = 1; day <= days_of_the_week; day++) {
          info.payload.rules.push({
            day,
            start_hour: this.start_hour,
            end_hour: this.end_hour,
          });
        }
      } else if (this.coupleOfDays) {
        /* SE FOR ALGUNS DIAS DA SEMANA */
        for (let i = 0; i < this.days.length; i++) {
          let day = this.days[i];
          info.payload.rules.push({
            day,
            start_hour: this.start_hour,
            end_hour: this.end_hour,
          });
        }
      } else {
        /* SE FOR SOMENTE NO DIA MESMO (DATA ESPECIAL) */
        const special_date = this.todayDate();
        if (!info.list_special_dates) {
          info.list_special_dates = special_date;
        } else {
          info.list_special_dates += "," + special_date;
        }
      }
      return info;
    },

    setEditingRule(value) {
      const rule = value || this.default;
      for (let key in this.default) {
        this[key] = rule[key];
      }
    },

    todayDate() {
      return formatDate(new Date());
    },
  },
  beforeMount() {
    if (!this.days.length) this.days.push(this.currentWeekDayNumber);
  },
};
</script>

<template>
  <v-card outlined :loading="loadingSelectedGroup" v-if="showRules">
    <!-- BOTÃO PARA ADICIONAR NOVA REGRA -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          @click="openDialog = true"
          :absolute="$vuetify.breakpoint.mdAndUp"
          :fixed="$vuetify.breakpoint.smAndDown"
          :bottom="$vuetify.breakpoint.smAndDown"
          :right="$vuetify.breakpoint.smAndDown"
          color="accent"
          class="mt-9"
          :style="`${$vuetify.breakpoint.mdAndUp ? 'margin-left: -35px' : ''}`"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-text="'mdi-plus'" />
        </v-btn>
      </template>
      <span v-text="`${$ml.get('add_rules')}`" />
    </v-tooltip>

    <v-data-table
      v-model="selected"
      :headers="headers"
      mobile-breakpoint="0"
      :items="rules"
      item-key="id"
      sort-by="day"
      show-select
      checkbox-color="accent"
      :loading="loadingSelectedGroup"
      :class="`${$vuetify.theme.dark ? 'background' : ''} elevation-0`"
      :style="`${
        $vuetify.theme.dark ? 'border-bottom: 1px solid #424242 !important' : ''
      }`"
    >
      <template v-slot:loading> {{ $ml.get("loading") }} </template>
      <template v-slot:no-data> {{ $ml.get("no_data") }} </template>
      <template v-slot:no-results> {{ $ml.get("no_results") }} </template>

      <template v-slot:top>
        <v-row
          :class="`${$vuetify.breakpoint.xs ? '' : 'd-flex align-center'} ma-0`"
          @click="$emit('close')"
        >
          <v-col xl="9" lg="9" md="9" xs="12" class="py-0">
            <v-card-title class="px-3 link">
              {{ $ml.get("time_settings") }}
            </v-card-title>
          </v-col>
          <v-col xl="3" lg="3" md="3" xs="12" class="d-flex align-center py-0">
            <v-spacer />
            <v-btn text class="text-none" large>
              <v-icon v-text="'mdi-arrow-left'" left small />
              voltar
            </v-btn>
          </v-col>
        </v-row>
        <v-divider />
        <v-row
          :class="`${edit ? 'grey lighten-3' : ''} ${
            hasSelectedToEdit ? 'orange lighten-5' : ''
          } ma-0 pa-0`"
        >
          <v-col
            :class="
              $vuetify.breakpoint.xs ? 'pb-6' : 'd-flex align-center py-0 px-1'
            "
          >
            <v-card-subtitle
              v-if="hasSelectedToEdit"
              class="text-subtitle-1 ml-0 mr-2 px-1 py-2 font-weight-medium"
            >
              <v-btn icon large class="mr-2 ml-0" @click="selected = []">
                <v-icon v-text="'mdi-close'" />
              </v-btn>
              {{
                `${selectedNumber} ${
                  selectedNumber > 1
                    ? $ml.get("selected_rules")
                    : $ml.get("selected_rule")
                }`
              }}
            </v-card-subtitle>

            <!-- LABEL: MOSTRANDO TODAS AS REGRAS -->
            <v-card-subtitle
              v-else
              class="text-subtitle-1 mx-1 font-weight-medium"
            >
              {{ $ml.get("showing_all_rules") }}
            </v-card-subtitle>

            <!-- BOTÃO DE ADICIONAR REGRAS -->
            <v-btn
              v-if="!edit && !hasSelectedToEdit"
              small
              elevation="0"
              color="accent"
              text
              :class="`${
                $vuetify.breakpoint.xs ? 'ml-2' : ''
              } text-none text-body-2 font-weight-medium`"
              @click="openDialog = true"
            >
              {{ $ml.get("add_rules") }}
            </v-btn>

            <!-- BOTÃO DE EDITAR REGRAS SELECIONADAS -->
            <v-btn
              v-if="edit || hasSelectedToEdit"
              small
              elevation="0"
              color="accent"
              text
              @click="updateRules(selected)"
              :class="`${
                $vuetify.breakpoint.xs ? 'ml-2' : ''
              } text-none text-body-2 font-weight-medium`"
            >
              {{ `${$ml.get("update_selected_rules")}` }}
            </v-btn>

            <!-- BOTÃO DE REMOVER REGRAS SELECIONADAS -->
            <v-btn
              v-if="edit || hasSelectedToEdit"
              small
              elevation="0"
              color="accent"
              text
              @click="removeSelectedRules()"
              :class="`${
                $vuetify.breakpoint.xs ? 'ml-2' : ''
              } text-none text-body-2 font-weight-medium`"
            >
              {{ `${$ml.get("remove_selected_rules")}` }}
            </v-btn>
          </v-col>
          <!-- ÁREA DE VISUALIZAÇÃO -->
          <!-- <v-col
            v-if="!hasSelectedToEdit"
            :class="`${
              $vuetify.breakpoint.mdAndUp
                ? 'd-flex align-center pl-auto'
                : 'pt-0 mt-0'
            }`"
          > -->
          <!-- BOTÃO PARA ALTERNAR ENTRE VISUALIAÇÃO DAS REGRAS E REGRAS APLICADAS NO CALENDÁRIO -->
          <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  elevation="0"
                  icon
                  @click="display_table = !display_table"
                  :class="`${
                    $vuetify.breakpoint.mdAndUp ? 'ml-auto mr-2' : 'ml-2'
                  }  text-none text-body-2 font-weight-medium`"
                >
                  <v-icon v-text="'mdi-calendar-week'" />
                </v-btn>
              </template>
              {{ `${$ml.get("to_view")} ${$ml.get("week")}` }}
            </v-tooltip>
          </v-col> -->
        </v-row>
        <v-divider />
      </template>

      <!-- HEADERS -->
      <template v-slot:[`header.day`]="{}">
        <span class="text-capitalize">
          {{ $ml.get("day") }}
        </span>
      </template>
      <template v-slot:[`header.start_hour`]="{ header }">
        {{ $ml.get(header.value) }}
      </template>
      <template v-slot:[`header.end_hour`]="{ header }">
        {{ $ml.get(header.value) }}
      </template>

      <!-- TBODY -->
      <template v-slot:[`item.day`]="{ item }">
        <span class="text-capitalize">
          {{ getWeeyDayNameByNumber(item.day) }}
        </span>
      </template>
      <template v-slot:[`item.updated_password_at`]="{ item }">
        {{
          item.updated_password_at
            ? formatDateFullMonth(item.updated_password_at)
            : "-"
        }}
      </template>
      <template v-slot:[`item.more`]="{ item }">
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              right
              color="grey"
              icon
              v-bind="attrs"
              v-on="on"
              :disabled="selected.length > 1"
            >
              <v-icon v-text="'mdi-dots-vertical'" />
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="updateRules([item])" class="py-0 text-body-2">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-content>{{
                $ml.get("edit_rule")
              }}</v-list-item-content>
            </v-list-item>
            <v-list-item @click="removeRule(item.id)" class="py-0 text-body-2">
              <v-list-item-icon class="mr-2">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>{{
                $ml.get("remove_rule")
              }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <AddOrEditRulesDialog
      :show="openDialog"
      :editingRule="editingRule"
      @close="
        openDialog = false;
        editingRule = null;
        selected = [];
      "
    />
  </v-card>
</template>
<script>
import { getWeeyDayNameByNumber } from "@/helpers/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";
import AddOrEditRulesDialog from "@/components/groups/AddOrEditRulesDialog";

export default {
  props: {
    show: { type: Boolean, default: false },
  },
  components: {
    AddOrEditRulesDialog,
  },
  data() {
    return {
      selected: [],
      display_table: true,
      edit: false,
      openDialog: false,
      editingRule: null,
    };
  },
  watch: {
    "$route.path"() {
      this.removeEditMode();
    },
  },
  computed: {
    ...mapGetters([
      "loadingSelectedGroup",
      "loadingUsers",
      "selectedGroup",
      "userDefaultImage",
      "users",
      "groups",
    ]),

    rules() {
      return this.selectedGroup.rules || [];
    },

    selectedNumber() {
      return this.selected.length;
    },

    hasSelectedToEdit() {
      return !this.edit && this.selected.length > 0;
    },

    showRules: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    allUsers() {
      return this.users
        .map((user) => {
          let user_groups = (user.rules || []).map((group) => {
            return group.group_key;
          });
          user.groups =
            (this.groups || [])
              .filter((group) => user_groups.includes(group.key))
              .map((group) => group.name)
              .join(", ") || this.$ml.get("no_group");
          return user;
        })
        .filter(
          (user) =>
            !this.groupParticipantsWithTheirGroups.find(
              (participant) => participant.username === user.username
            )
        );
    },

    groupParticipantsWithTheirGroups() {
      return this.groupParticipants.map((user) => {
        let user_groups = user.rules.map((group) => {
          return group.group_key;
        });
        user.groups =
          this.groups
            .filter((group) => user_groups.includes(group.key))
            .map((group) => group.name)
            .join(", ") || this.$ml.get("no_group");
        return user;
      });
    },

    groupParticipants() {
      return this.selectedGroup.users || [];
    },

    allMembers() {
      return this.edit ? this.allUsers : this.groupParticipantsWithTheirGroups;
    },
    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "day",
        },
        {
          value: "start_hour",
          groupable: false,
          sortable: false,
        },
        { value: "end_hour", groupable: false, sortable: false },
        { value: "more", width: 45, groupable: false, sortable: false },
      ];
    },
    groupKey() {
      return this.selectedGroup.key || null;
    },
  },
  methods: {
    ...mapActions(["deleteGroupRule"]),
    ...mapMutations(["removeEditMode"]),

    /* formatDateFullMonth, */

    getWeeyDayNameByNumber,

    async updateRules(rules = []) {
      const rule = rules[0];
      const onlyOne = rules.length == 1;
      this.editingRule = {
        ...rule,
        rules_ids: rules.map((item) => item.id),
        days: onlyOne ? rule.day : rules.map((item) => item.day),
        type: "choose_days",
      };
      this.openDialog = true;
    },

    async removeRule(id) {
      let info = {
        key: this.groupKey,
        payload: {
          id_rules: [id],
        },
      };
      await this.deleteGroupRule(info);
    },

    async removeSelectedRules() {
      let info = {
        key: this.groupKey,
        payload: {
          id_rules: this.selected.map((item) => item.id),
        },
      };
      await this.deleteGroupRule(info);
      this.selected = [];
    },
  },
};
</script>

<style scoped>
tr td button {
  visibility: hidden;
}
tr:hover td button {
  visibility: visible;
}
</style>

<template>
  <v-card class="my-0" max-width="500">
    <v-card-title v-text="$ml.get('add_new_rule')" />

    <v-card-text class="d-flex flex-column justiy-center">
      <v-select :items="optionsWhenRuleOccur" v-model="eventDetails.type">
        <template v-slot:prepend>
          <v-icon
            class="mr-3"
            v-text="'mdi-calendar'"
            :dark="$vuetify.theme.dark"
          />
        </template>
      </v-select>

      <v-btn-toggle
        v-model="eventDetails.days"
        multiple
        v-if="canChooseDays"
        class="mx-auto mt-2 mb-8"
      >
        <v-btn text small v-for="(item, i) in weekDays" :key="i" :value="i + 1">
          {{ item.day_name }}
        </v-btn>
      </v-btn-toggle>

      <!-- DEFINIR PERÍODO DE TEMPO -->

      <v-row no-gutters class="mb-2 pb-0">
        <v-col class="mb-0 pb-0" cols="6">
          <!-- DEFINIR HORA INICIAL -->
          <v-menu
            ref="startHourMenu"
            v-model="startHourMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            :disabled="eventDetails.all_day || canNotChooseHour"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :error="ruleDisableStarHourLaterThanEndHour"
                :error-messages="customErrorMessage"
                :label="$ml.get('start_hour')"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="eventDetails.start_hour"
                :disabled="eventDetails.all_day || canNotChooseHour"
                style="max-height: 50px"
              >
                <template v-slot:prepend>
                  <v-icon
                    class="mr-3"
                    v-text="'mdi-clock-time-four-outline'"
                    :dark="$vuetify.theme.dark"
                  />
                </template>
              </v-text-field>
            </template>
            <v-time-picker
              v-if="startHourMenu"
              v-model="eventDetails.start_hour"
              format="24hr"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col class="mb-0 pb-0" cols="6">
          <!-- DEFINIR HORA FINAL -->
          <v-menu
            ref="endHourMenu"
            v-model="endHourMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            :disabled="eventDetails.all_day || canNotChooseHour"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :error="ruleDisableStarHourLaterThanEndHour"
                :label="$ml.get('end_hour')"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="eventDetails.end_hour"
                :disabled="eventDetails.all_day || canNotChooseHour"
                style="max-height: 50px"
              >
                <template v-slot:prepend>
                  <div class="d-flex my-auto mx-2">-</div>
                </template>
              </v-text-field>
            </template>
            <v-time-picker
              v-if="endHourMenu"
              v-model="eventDetails.end_hour"
              format="24hr"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col>
          <Alert
            v-if="ruleDisableStarHourLaterThanEndHour"
            :showAction="false"
            icon="mdi-alert-octagon"
            color="error"
            dense
            type="error"
            class="mb-0 mt-8"
          >
            <span
              >{{ $ml.get("starthour_later_than_endhour") }}
              <a
                href="https://conectasuite.com/perguntas-frequentes/horario-inicial-maior-que-horario-final-em-turnos/"
                target="_blank"
                >{{ $ml.get("need_help_question") }}</a
              >
            </span>
          </Alert>
        </v-col>
      </v-row>

      <!-- DEFINIR COMO O DIA TODO -->
      <v-checkbox
        v-model="eventDetails.all_day"
        dense
        :disabled="canNotChooseHour"
        :false-value="false"
        :true-value="true"
        :indeterminate="false"
        class="mt-4 mb-0 py-0"
        :label="`${$ml.get('define_as')} ${$ml.get('all_day').toLowerCase()}`"
        style="max-height: 35px; width: fit-content"
      ></v-checkbox>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="secondary" text @click="$emit('close')">
        {{ $ml.get("cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        text
        @click="save()"
        :disabled="registeredSpecialDate || ruleDisableStarHourLaterThanEndHour"
      >
        {{ $ml.get("save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {
  getWeekDays,
  getCurrentWeekDay,
  formatDate,
  addSpecificHour,
} from "@/helpers/utils";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddOrEditHourRuleInCalendar",
  props: {
    groupKey: { type: String, required: true },
    date: { type: String, default: "" },
    selected: { type: Object, default: () => {} },
  },
  data() {
    return {
      startHourMenu: false,
      endHourMenu: false,
      eventDetails: {
        start_date: this.date,
        start_hour: this.selected.start_hour,
        end_date: this.date,
        end_hour: addSpecificHour(this.selected.full_date, 1),
        type: "weekly",
        special_date: false,
        days: [],
      },
    };
  },
  watch: {
    "eventDetails.all_day"() {
      if (this.eventDetails.all_day) {
        this.eventDetails.start_hour = "00:00";
        this.eventDetails.end_hour = "23:59";
      }
    },
    "eventDetails.type"() {
      this.eventDetails.all_day = false;
      if (["weekly"].includes(this.eventDetails.type)) {
        this.eventDetails.days = [];
        this.eventDetails.days.push(this.currentWeekDayNumber);
      }
    },
  },
  computed: {
    ...mapGetters(["selectedGroup", "specialDates"]),

    weekDays() {
      return getWeekDays();
    },

    currentWeekDay() {
      return getCurrentWeekDay(this.selected.date);
    },

    currentWeekDayNumber() {
      return this.selected.weekday;
    },

    canChooseDays() {
      return this.eventDetails.type === "choose_days";
    },
    optionsWhenRuleOccur() {
      return [
        {
          text: this.$ml.get("everyday"),
          value: "every_day",
        },
        {
          text: this.$ml.get("choose_days"),
          value: "choose_days",
        },
        {
          text: `${this.$ml.get("weekly")}: ${this.$ml.get("each")} ${
            this.currentWeekDay
          }`,
          value: "weekly",
        },
      ];
    },

    coupleOfDays() {
      return ["choose_days", "weekly"].includes(this.eventDetails.type);
    },
    allDays() {
      return ["every_day"].includes(this.eventDetails.type);
    },

    registeredSpecialDate() {
      const special_date = this.getSelectedDateFormated_DD_MM_YYYY();
      const date = this.specialDates.indexOf(special_date);
      if (date === -1) {
        return false;
      }
      return true;
    },

    ruleDisableStarHourLaterThanEndHour() {
      return this.eventDetails.start_hour > this.eventDetails.end_hour;
    },

    customErrorMessage() {
      return this.ruleDisableStarHourLaterThanEndHour
        ? this.$ml.get("starthour_later_than_endhour")
        : "";
    },
  },
  methods: {
    ...mapActions(["setGroupRule", "setGroup"]),

    async save() {
      const payload = await this.getPayload();
      this.setGroupRule(payload);
      this.$emit("close");
    },

    getPayload() {
      let info = {};
      info.key = this.groupKey;
      info.payload = {};
      info.payload.rules = [];
      info.list_special_dates = this.selectedGroup.list_special_dates;
      /* SE FOR TODOS OS DIAS */
      if (this.allDays) {
        for (let i = 0; i < 7; i++) {
          info.payload.rules.push({
            day: i + 1,
            start_hour: this.eventDetails.start_hour,
            end_hour: this.eventDetails.end_hour,
          });
        }
      } else if (this.coupleOfDays) {
        /* SE FOR ALGUNS DIAS DA SEMANA */
        for (let i = 0; i < this.eventDetails.days.length; i++) {
          let day = this.eventDetails.days[i];
          info.payload.rules.push({
            day: day,
            start_hour: this.eventDetails.start_hour,
            end_hour: this.eventDetails.end_hour,
          });
        }
      } else {
        /* SE FOR SOMENTE NO DIA MESMO (DATA ESPECIAL) */
        const date = this.getSelectedDateFormated_DD_MM_YYYY();
        if (info.list_special_dates === "") {
          info.list_special_dates = date;
        } else {
          info.list_special_dates += "," + date;
        }
      }
      return info;
    },

    getSelectedDateFormated_DD_MM_YYYY() {
      return formatDate(this.selected.date);
    },
  },
  beforeMount() {
    this.eventDetails.days.push(this.currentWeekDayNumber);
  },
};
</script>

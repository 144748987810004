<template>
  <v-card
    outlined
    :loading="loadingSelectedGroup"
    v-if="showParticipants"
    height="75vh"
    id="cardGroupParticipants"
  >
    <!-- BOTÃO PARA ADICIONAR NOVOS PARTICIPANTES -->
    <v-tooltip v-if="!edit && !hasSelectedToEdit" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          @click="showAddParticipantsDialog = true"
          :absolute="$vuetify.breakpoint.mdAndUp"
          :fixed="$vuetify.breakpoint.smAndDown"
          :bottom="$vuetify.breakpoint.smAndDown"
          :right="$vuetify.breakpoint.smAndDown"
          color="accent"
          class="mt-9"
          :style="`${
            $vuetify.breakpoint.mdAndUp
              ? 'margin-left: -35px;'
              : 'margin-bottom: 70px;'
          }`"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon v-text="'mdi-plus'" />
        </v-btn>
      </template>
      <span v-text="`${$ml.get('add_participants')}`" />
    </v-tooltip>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="allMembers"
      item-key="email"
      :search="search"
      show-select
      checkbox-color="accent"
      @toggle-select-all="getPageSelection"
      :loading="loadingSelectedGroup || loadingUsers || loadingSync"
      :class="`${$vuetify.theme.dark ? 'background' : ''} elevation-0`"
      :style="`${
        $vuetify.theme.dark ? 'border-bottom: 1px solid #424242 !important' : ''
      }`"
      :footer-props="{
        'items-per-page-options': [50, 100, 200],
      }"
      :items-per-page="50"
      fixed-header
      :height="tableHeight"
    >
      <template v-slot:loading> {{ $ml.get("loading") }} </template>
      <template v-slot:no-data> {{ $ml.get("no_data") }} </template>
      <template v-slot:no-results> {{ $ml.get("no_results") }} </template>

      <template v-slot:top>
        <div id="usersTableHeader">
          <v-row
            :class="`${
              $vuetify.breakpoint.xs ? '' : 'd-flex align-center'
            } ma-0`"
            @click="$emit('close')"
          >
            <v-col xl="9" lg="9" md="9" xs="12" class="py-0">
              <v-card-title class="px-3 link">
                {{ $ml.get("participants") }}
              </v-card-title>
            </v-col>
            <v-col
              xl="3"
              lg="3"
              md="3"
              xs="12"
              class="d-flex align-center py-0"
            >
              <v-spacer />
              <v-btn text class="text-none" large>
                <v-icon v-text="'mdi-arrow-left'" left small />
                voltar
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            :class="`${edit ? 'grey lighten-3' : ''} ${
              hasSelectedToEdit ? 'orange lighten-5' : ''
            } ma-0 pa-0`"
          >
            <v-col
              :class="
                $vuetify.breakpoint.xs
                  ? 'pb-6'
                  : 'd-flex align-center py-0 px-1'
              "
            >
              <v-card-subtitle
                v-if="hasSelectedToEdit || selectedNumber"
                class="subtitle-1 ml-0 mr-2 px-1 py-2 font-weight-medium"
              >
                <v-btn
                  icon
                  large
                  class="mr-2 ml-0"
                  @click="(selected = []), (edit = false)"
                >
                  <v-icon v-text="'mdi-close'" />
                </v-btn>
                {{ `${selectedNumber} ${$ml.get("participants_selected")}` }}
              </v-card-subtitle>
              <v-card-subtitle
                v-else
                class="subtitle-1 mx-1 font-weight-medium"
              >
                {{
                  edit
                    ? `${$ml.get("show_all_users")} ${$ml.get("except")} ${$ml
                        .get("participants")
                        .toLowerCase()}`
                    : `${$ml.get("show_all_participants")}`
                }}
              </v-card-subtitle>
              <div v-if="edit && !hasSelectedToEdit">
                <v-btn
                  :disabled="selectedNumber === 0"
                  small
                  text
                  elevation="0"
                  color="accent"
                  @click="openDialog(true)"
                  :class="`${
                    $vuetify.breakpoint.xs ? 'mx-2' : ''
                  } text-none text-body-2 font-weight-medium`"
                >
                  {{ $ml.get("add_selected") }}
                </v-btn>
              </div>
              <v-btn
                v-else-if="!edit && !hasSelectedToEdit"
                small
                elevation="0"
                color="accent"
                text
                @click="showAddParticipantsDialog = true"
                :class="`${
                  $vuetify.breakpoint.xs ? 'ml-2' : ''
                } text-none text-body-2 font-weight-medium`"
              >
                {{ $ml.get("add_participants") }}
              </v-btn>
              <v-btn
                v-else-if="hasSelectedToEdit"
                small
                elevation="0"
                color="accent"
                text
                @click="openDialog()"
                :class="`${
                  $vuetify.breakpoint.xs ? 'ml-2' : ''
                } text-none text-body-2 font-weight-medium`"
              >
                {{
                  `${$ml.get("remove")} ${$ml
                    .get("participants")
                    .toLowerCase()}`
                }}
              </v-btn>
            </v-col>
          </v-row>
          <v-divider v-if="emailsNotFound.length > 0" />
          <Alert
            v-if="emailsNotFound.length > 0"
            dense
            type="warning"
            class="mb-0"
          >
            <v-row class="font-weight-medium pt-1 ma-0">
              <span>{{ $ml.get("error_participants_to_add_not_found") }}:</span>
              <v-spacer />
              <v-btn color="brown" small text @click="emailsNotFound = []">
                {{ $ml.get("close") }}
              </v-btn>
            </v-row>
            <v-card
              flat
              class="transparent black--text overflow-y-auto"
              max-height="80"
            >
              <ul>
                <li v-for="item in emailsNotFound" :key="item" v-text="item" />
              </ul>
            </v-card>
          </Alert>
          <v-divider />
          <v-row v-if="edit && selectedNumber" class="pt-5 pb-0 px-4 max">
            <v-chip
              v-for="user in selected"
              :key="user.user_id"
              pill
              small
              class="ma-1"
            >
              <span class="pr-2">
                {{ user.username }}
              </span>
              <v-icon
                small
                @click="removeUserFromSelectedList(user.user_id)"
                v-text="'mdi-close-circle'"
              />
            </v-chip>
          </v-row>
          <v-divider v-if="edit && selectedNumber" />
          <v-row style="max-height: 72px" class="px-0">
            <v-text-field
              v-model="search"
              :placeholder="$ml.get('search_users')"
              class="elevation-0 mx-1 mt-4 mb-0 transparent"
              background-color="transparent"
              filled
              dense
              rounded
              height="25"
              full-width
              persistent-placeholder
            >
              <template v-slot:prepend-inner>
                <v-icon v-text="'mdi-magnify'" class="my-0 mr-7" left />
              </template>
            </v-text-field>
          </v-row>
          <v-divider />
        </div>
        <v-row
          v-if="allSelectedAreInTheSamePage || allSelected"
          :class="`${
            $vuetify.theme.dark ? 'grey darken-2' : 'grey lighten-3'
          } d-flex align-center justify-center px-2 my-0 py-2 ma-0 text-body-2`"
          :style="$vuetify.breakpoint.mdAndUp ? 'height: 40px' : ''"
        >
          <!-- TEXTO QUE APARECE QUANDO TODOS OS ITEMS DA PÁGINA ESTÃO SELECIONADOS -->
          <div
            v-if="allSelectedAreInTheSamePage && !allSelected"
            class="d-flex align-center text-wrap"
          >
            {{ $ml.get("all_of") }}
            <span class="font-weight-medium mx-1">{{ selectedNumber }}</span>
            {{ $ml.get("users").toLowerCase() }}
            {{ $ml.get("page_selected") }}
            <v-btn
              text
              x-small
              color="info darken-2"
              :block="$vuetify.breakpoint.sm"
              class="text-none font-weight-medium text-body-2 px-1 py-0 my-0"
              @click="selectAllUsersToRemove()"
            >
              {{ $ml.get("select_all_of") }}
              {{ $ml.get("users").toLowerCase() }}
            </v-btn>
          </div>
          <!-- TEXTO QUE APARECE QUANDO TODOS OS ITENS DA TABELA ESTÃO SELECIONADOS -->
          <div v-if="allSelected" class="d-flex align-center text-wrap">
            {{ $ml.get("all_of") }}
            <span class="font-weight-medium mx-1">{{ selectedNumber }}</span>
            {{ $ml.get("users").toLowerCase() }}
            {{ $ml.get("table_selected") }}
            <v-btn
              text
              x-small
              color="info darken-2"
              class="text-none font-weight-medium text-body-2 px-1 py-0 my-0"
              @click="selected = []"
            >
              {{ $ml.get("clear_selection") }}
            </v-btn>
          </div>
        </v-row>
      </template>

      <!-- INÍCIO: HEADERS -->
      <template v-slot:[`header.username`]="{}">
        {{ $ml.get("participant") }}
      </template>
      <template v-slot:[`header.email`]="{ header }">
        {{ $ml.get(header.value) }}
      </template>
      <template v-slot:[`header.updated_password_at`]="{ header }">
        {{ $ml.get(header.value) }}
      </template>
      <template v-slot:[`header.groups`]="{ header }">
        {{ $ml.get(header.value) }}
      </template>
      <template v-slot:[`header.is_admin`]="{}">
        {{ $ml.get("google_admin") }}
      </template>
      <template v-slot:[`item.is_admin`]="{ item }">
        {{ item.is_admin ? $ml.get("yes") : $ml.get("no") }}
      </template>
      <template v-slot:[`item.username`]="{ item }">
        <div class="d-flex align-center">
          <v-avatar size="25" class="mr-2">
            <img :src="item.photo || userDefaultImage" :alt="item.username" />
          </v-avatar>
          <span class="font-weight-medium">{{
            item.name || item.username
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.updated_password_at`]="{ item }">
        {{
          item.updated_password_at
            ? formatDateFullMonth(item.updated_password_at)
            : "-"
        }}
      </template>
      <template v-slot:[`item.more`]="{}">
        <v-btn text color="accent">
          {{ $ml.get("remove") }}
        </v-btn>
      </template>
      <template v-slot:[`item.groups`]="{ item }">
        {{ item.groups.join(", ") || $ml.get("no_group") }}
      </template>
    </v-data-table>

    <HeaderDialog
      id="edit-group-participants"
      :title="
        isAdding
          ? $ml.get('add_participants')
          : `${$ml.get('remove')} ${$ml.get('participants').toLowerCase()}`
      "
      :show="showDialog"
      :loading="loading"
      width="600"
      @close="showDialog = false"
      @action="editGroupparticipants"
    >
      <template v-slot:body>
        <v-row>
          <v-col>
            <p>
              {{
                isAdding
                  ? $ml.get("add_group_dialog_description")
                  : $ml.get("remove_group_dialog_description")
              }}
            </p>
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>
    <HeaderDialog
      :show="feedbackDialog"
      :title="$ml.get('rate_your_experience')"
      :subtitle="$ml.get('rate_your_experience_subtitle')"
      width="600"
      readOnly
      customFooter
      :persistent="false"
      @close="feedbackDialog = false"
    >
      <template #body>
        <FeedbackRating
          v-if="feedbackDialog"
          action="group_add_or_remove_users"
          show-dismiss
          rating-class="d-flex justify-start ml-1"
          width="400"
          @close="feedbackDialog = false"
        />
      </template>
    </HeaderDialog>
    <HeaderDialog
      id="add_multiple_participants"
      :subtitle="selectedGroup.name"
      :title="$ml.get('add_participants')"
      :show="showAddParticipantsDialog"
      :loading="loadingNewParticipants"
      width="700"
      :actionText="`${$ml.get('add')} ${$ml.get('participants')}`"
      :closeText="'cancel'"
      :disabledAction="newParticipansToAdd.length === 0 || hasInvalidEmails"
      @close="showAddParticipantsDialog = false"
      @action="addMultipleParticipants"
    >
      <template v-slot:body>
        <v-row>
          <v-col>
            <p>
              {{ $ml.get("add_participants_label") }}
            </p>
          </v-col>
        </v-row>

        <Alert dense type="info" class="mb-4 mx-0" :showAction="false">
          <span v-html="$ml.get('combobox_helper')" />
        </Alert>

        <Alert
          v-if="invalidEmails.length > 0 && hasInvalidEmails"
          dense
          type="error"
          class="mb-4"
        >
          <span class="font-weight-medium">
            {{ $ml.get("error_invalid_emails_examples") }}
          </span>
        </Alert>
        <v-row class="ma-0 mt-2">
          <v-combobox
            v-model="newParticipansToAdd"
            :items="usersThatAreNotInThisGroup"
            :label="`${$ml.get('type_paste_or_search')} ${$ml
              .get('users')
              .toLowerCase()}`"
            deletable-chips
            multiple
            :delimiters="[',', ' ', '\n', '\t', ';']"
            small-chips
            validate-on-blur
            :return-object="false"
            @change="delimit"
            item-value="email"
            item-text="email"
            append-icon=""
            auto-select-first
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-bind="attrs"
                :color="invalidEmails.includes(item) ? 'error' : ''"
                :input-value="selected"
                small
              >
                <span class="pr-2">
                  {{ item }}
                </span>
                <v-icon small @click="parent.selectItem(item)">
                  mdi-close-circle
                </v-icon>
              </v-chip>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar>
                <v-avatar size="30">
                  <img
                    :src="item.photo || userDefaultImage"
                    :alt="item.username"
                  />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name || item.username }}
                </v-list-item-title>
                <v-list-item-subtitle v-text="item.email" />
              </v-list-item-content>
            </template>
          </v-combobox>
        </v-row>
        <v-row v-if="showAdvancedOptions" class="ma-0 pa-0 mt-4">
          <v-list-item three-line class="ma-0 pa-0">
            <v-list-item-content class="pa-0 ma-0">
              <v-list-item-title
                class="mb-4 link"
                @click="showAdvancedOptions = false"
                >Avançado</v-list-item-title
              >
              <v-list-item-subtitle>
                <Alert dense color="warning" class="mb-0">
                  {{ $ml.get("note") }}
                  {{ $ml.get("add_all_users_label") }}</Alert
                >
              </v-list-item-subtitle>
              <v-list-item-subtitle class="px-2 mt-0 py-0">
                <v-checkbox
                  v-model="selectAllUsers"
                  :true-value="true"
                  :false-value="false"
                  :indeterminate="false"
                  style="width: fit-content"
                >
                  <template v-slot:label>
                    {{ $ml.get("add_all_users_confirm_action") }}
                    <span class="font-weight-bold ml-1">{{
                      selectedGroupName
                    }}</span>
                  </template>
                </v-checkbox>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-row>
      </template>
      <template v-slot:footer-left>
        <v-btn
          @click="showAdvancedOptions = true"
          v-if="!showAdvancedOptions"
          text
          class="text-body-1 text-none"
        >
          {{ $ml.get("advanced") }}
        </v-btn>
      </template>
    </HeaderDialog>
    <v-snackbar v-model="showSnackbarAllSelected" bottom>
      {{ $ml.get("all_of") }}
      {{ $ml.get("users").toLowerCase() }}
      {{ $ml.get("table_selected_snackbar") }}
      <template v-slot:action="{ attrs }">
        <v-btn dark ext v-bind="attrs" @click="showSnackbarAllSelected = false">
          {{ $ml.get("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
import { formatDateFullMonth, renewFeedback } from "@/helpers/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";
import HeaderDialog from "@/components/base/HeaderDialog";
import FeedbackRating from "@/components/forms/FeedbackRating";
import { setFirebaseEvent } from "@/helpers/registerAnalytics";

export default {
  name: "EditGroupMembers",
  components: { HeaderDialog, FeedbackRating },
  props: {
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      showAdvancedOptions: false,
      showAddParticipantsDialog: false,
      loadingNewParticipants: false,
      selected: [],
      newParticipansToAdd: [],
      search: "",
      edit: false,
      isAdding: false,
      showDialog: false,
      loading: false,
      pageHeight: 0,
      headerHeight: 0,
      selectAllUsers: false,
      emailsNotFound: [],
      invalidEmails: [],
      allItemsInThePage: [],
      showSnackbarAllSelected: false,
      feedbackDialog: false,
    };
  },
  watch: {
    showParticipants() {
      if (this.showParticipants) {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
      } else {
        window.removeEventListener("resize", this.handleResize);
      }
    },
    showAddParticipantsDialog() {
      if (this.showAddParticipantsDialog) {
        this.newParticipansToAdd = [];
        this.showAdvancedOptions = false;
        this.invalidEmails = [];
      }
    },
    selectAllUsers() {
      if (this.selectAllUsers) {
        this.newParticipansToAdd = this.usersThatAreNotInThisGroup.map(
          (user) => user.email
        );
      } else {
        this.newParticipansToAdd = [];
      }
    },

    allSelected() {
      if (this.allSelected) {
        this.showSnackbarAllSelected = true;
      }
    },
    feedbackDialog(value) {
      if (!value && !this.userHasAlreadySentFeedback) {
        setFirebaseEvent(
          this.mainDomain,
          "feedback_not_sent",
          "after_edit_group_members",
          this.currentUser.email
        );
      }
    },
  },
  computed: {
    ...mapGetters([
      "loadingSelectedGroup",
      "loadingUsers",
      "loadingSync",
      "selectedGroup",
      "userDefaultImage",
      "users",
      "groups",
      "userDefaultImage",
      "selectedGroupName",
      "currentUserFeedbacks",
      "mainDomain",
      "currentUser",
    ]),

    userHasAlreadySentFeedback() {
      return this.renewFeedback(
        this.currentUserFeedbacks.group_add_or_remove_users
      );
    },

    hasInvalidEmails() {
      return (
        this.newParticipansToAdd.filter((email) =>
          this.invalidEmails.includes(email)
        ).length > 0
      );
    },

    footerHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 100 : 64;
    },

    tableHeight() {
      return this.pageHeight - this.headerHeight - this.footerHeight;
    },

    selectedNumber() {
      return this.selected.length;
    },

    hasSelectedToEdit() {
      return !this.edit && this.selected.length > 0;
    },

    showParticipants: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    allUsers() {
      const filteredUsers = this.users
        .map((user) => {
          const { rule_group: userGroups } = user;

          const userGroupNames = this.groups
            .filter((group) => userGroups.includes(group.key))
            .map(({ name }) => name)
            .join(", ");

          user.groups = userGroupNames || this.$ml.get("no_group");
          return user;
        })
        .filter((user) => {
          return !this.groupParticipantsWithTheirGroups.some(
            (participant) => participant.username === user.username
          );
        });

      return filteredUsers;
    },

    groupParticipantsWithTheirGroups() {
      if (this.loadingUsers || this.loadingSync) {
        return [];
      } else {
        return this.groupParticipants.map((participant) => {
          const { email } = participant;
          const userFind = this.users.find((user) => {
            return user.email === email;
          });
          participant.groups = userFind.groups;
          return participant;
        });
      }
    },

    usersThatAreNotInThisGroup() {
      return this.users.filter(
        (user) =>
          !this.groupParticipants.find(
            (participant) => participant.email === user.email
          )
      );
    },

    groupParticipants() {
      return this.selectedGroup.users || [];
    },

    allMembers() {
      return this.edit ? this.allUsers : this.groupParticipantsWithTheirGroups;
    },
    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "username",
          width: 250,
        },
        {
          value: "email",
        },
        { value: "groups", filterable: false },
      ];
    },
    groupKey() {
      return this.selectedGroup.key || null;
    },

    allSelectedAreInTheSamePage() {
      if (this.selected.length > 0) {
        return (
          this.selected.every((item) =>
            this.allItemsInThePage.includes(item)
          ) && this.allItemsInThePage.length === this.selected.length
        );
      } else {
        return false;
      }
    },

    allSelected() {
      return (
        this.allMembers.length === this.selected.length &&
        this.allMembers.length > 0
      );
    },
  },
  methods: {
    ...mapActions([
      "editGroupMembers",
      "setSnackbarDefault",
      "setSnackbarCustomMessage",
      "updateUsersOnStore",
    ]),
    ...mapMutations([
      "setSelectedGroup",
      "updateGroupInGroups",
      "setLoadingSelectedGroup",
    ]),

    formatDateFullMonth,
    renewFeedback,

    async addMultipleParticipants() {
      this.loadingNewParticipants = true;
      this.setLoadingSelectedGroup(true);
      const url = `/groups/${this.groupKey}/users`;
      await this.$axios
        .put(url, { users: this.newParticipansToAdd })
        .then(({ data }) => {
          this.emailsNotFound = data.not_found;
          this.setSelectedGroup(data.group);
          this.updateGroupInGroups(data.group);
          this.updateUsersOnStore(data.users);
          if (data.not_found.length) {
            this.setSnackbarCustomMessage({
              message: `${this.$ml.get("success_group_update")}. ${this.$ml.get(
                "some_emails_could_not_be_added"
              )}.`,
              color: "warning darken-1",
              timeout: 15000,
            });
          } else {
            this.setSnackbarDefault({
              message: "success_group_update",
            });
          }
          this.showAdvancedOptions = false;

          if (this.userHasAlreadySentFeedback) {
            this.feedbackDialog = true;
          }
        })
        .catch(({ response }) => {
          if (response.data.users) {
            const keysToInvalidEmails = Object.keys(response.data.users);
            for (let i = 0; i < this.newParticipansToAdd.length; i++) {
              const email = this.newParticipansToAdd[i];
              if (keysToInvalidEmails.includes(i.toString())) {
                this.invalidEmails.push(email);
              }
            }
            this.setSnackbarDefault({
              message: "error_invalid_emails",
              color: "error",
            });
          } else {
            this.setSnackbarDefault({
              message: "error_update_group",
              color: "error",
            });
          }
          console.error("addMultipleParticipants(): ", response);
        })
        .finally(() => {
          this.showAdvancedOptions = false;
          this.loadingNewParticipants = false;
          this.setLoadingSelectedGroup(false);
          this.showAddParticipantsDialog = false;
        });
    },

    delimit(v) {
      const reducer = (a, e) => [...a, ...e.split(/[, ;]+/)];
      this.newParticipansToAdd = [...new Set(v.reduce(reducer, []))];
    },

    handleResize() {
      if (document.querySelector("#cardGroupParticipants")) {
        this.pageHeight = document.querySelector(
          "#cardGroupParticipants"
        ).offsetHeight;
      }
      if (document.querySelector("#usersTableHeader")) {
        this.headerHeight =
          document.querySelector("#usersTableHeader").offsetHeight;
      }
    },

    removeUserFromSelectedList(id) {
      this.selected = this.selected.filter((user) => user.user_id !== id);
    },

    openDialog(isAdding = false) {
      this.showDialog = true;
      this.isAdding = isAdding;
    },

    async editGroupparticipants() {
      this.loading = true;
      this.showDialog = false;
      this.isAdding
        ? await this.addParticipants()
        : await this.removeParticipants();
      this.loading = false;
    },

    addParticipants() {
      let info = {
        key: this.groupKey,
        payload: {
          users: this.selected.map((user) => user.email),
        },
      };
      this.editGroupMembers(info).then((response) => {
        this.updateUsersOnStore(response);
      });
      this.selected = [];
      this.edit = false;
    },
    removeParticipants() {
      let info = {
        key: this.groupKey,
        is_delete: true,
        payload: {
          users: this.selected.map((user) => user.email),
        },
      };
      this.editGroupMembers(info).then((response) => {
        this.updateUsersOnStore(response);
      });

      if (this.userHasAlreadySentFeedback) {
        this.feedbackDialog = true;
      }

      this.selected = [];
      this.edit = false;
    },

    getPageSelection(event) {
      if (event.value) {
        this.allItemsInThePage = event.items;
      } else {
        this.allItemsInThePage = [];
        this.selected = [];
      }
    },

    selectAllUsersToRemove() {
      if (this.isAdding) {
        this.selected = this.allMembers.slice();
      } else {
        this.selected = Object.assign(this.allMembers, {});
      }
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped>
.max {
  max-height: 10rem;
  overflow: scroll;
}
</style>

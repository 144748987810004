<template>
  <v-banner
    :value="value"
    :sticky="sticky"
    :class="`mx-0 mb-5 orange-border ${customClass}`"
  >
    <v-icon
      v-if="icon"
      slot="icon"
      :color="iconColor"
      :size="iconSize"
      v-text="icon"
    />
    <v-row align-content="center">
      <v-col lg="5" md="4" sm="12" align-self="center" class="orange-border">
        <v-img
          class=""
          v-if="image"
          width="100%"
          :height="imageHeight"
          :src="image"
        />
      </v-col>
      <v-col>
        <v-card flat class="transparent">
          <v-card-title v-if="title" class="banner-title-style">{{
            title
          }}</v-card-title>
          <v-card-text class="text-body-1" v-if="description">
            {{ description }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <template v-slot:actions>
      <div class="px-2 d-flex justify-end align-center">
        <v-btn text color="primary" v-if="dimissText" @click="$emit('dimiss')">
          {{ dimissText }}
        </v-btn>
        <v-btn text color="primary" v-if="actionText" @click="$emit('action')">
          {{ actionText }}
        </v-btn>
        <slot name="customActionButton" />
      </div>
    </template>
  </v-banner>
</template>
<script>
export default {
  name: "Banner",

  props: {
    value: { type: Boolean, default: true },
    image: { type: String, default: "" },
    imageWidth: { type: [String, Number], default: 300 },
    imageHeight: { type: [String, Number], default: "auto" },
    icon: { type: String, default: "" },
    iconColor: { type: String, default: "" },
    iconSize: { type: [String, Number], default: 35 },
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    actionText: { type: [String, Boolean], default: false },
    dimissText: { type: [String, Boolean], default: false },
    sticky: { type: Boolean, default: false },
    customClass: { type: String, default: "" },
  },

  data() {
    return {};
  },

  computed: {},
};
</script>

<style scoped>
.banner-title-style {
  word-break: break-word;
  max-width: 92%;
}
</style>
